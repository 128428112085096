// .vertical-container {
//     // position: absolute;
//     height: 75%;;
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//     justify-content: space-evenly;
//     z-index: 9;
// }

// .horizontal-container {
//     // margin-top: 18%;
    
//     width: 100%;
//     height: 100%;
//     // height: 50%;
//     display: flex;
//     flex-direction: row;
//     /* align-items: center; */
//     justify-content: space-evenly;
//     /* position: absolute; */
// }

// .lobby-buttons-container {
//     width: 50%;
//     justify-content: center;
// }

// .lobby-link {
//     border: 1px solid var(--orange);
//     border-radius: 10px;
//     padding: 10%;
//         background-color: var(--white);
//         color: var(--orange);
//         width: 30%;
//         height: auto;
//     margin: 15%;
// }

.lobby-button {
    cursor: pointer;
}

// .lobby-link:hover {
//     background-color: var(--orange);
//         color: var(--white);
//         cursor: pointer;
// }

// .or-container {
//     font-family: var(--font-body);
//     // align-items: space-evenly;
//     justify-content: center;
//     display: flex;
//     flex-direction: column;
//     margin: 5%;

// }


.demo_page {
    position: relative;
    top: 15vh;

    .flex-row > * {
        width: 20vw;
        margin: 0 5vw;
        height: 60vh;
    }

    .fa-solid {
        margin-bottom: 5vh;
        color: var(--orange);
        opacity: .5;
        font-size: 10vw;
    }

    img {
        height: 50%;
        margin-bottom: 3vh;
    }

    button.btn {
        border: 1px solid var(--orange);
        background: transparent;
        color: var(--orange);
        cursor: pointer;
    }

    button.btn:hover {
        border: 1px solid var(--orange);
        background-color: var(--orange);
        color: var(--white);
    }
}