.planning_map_wrapper {
  position: relative;
  top: -10vh;
  overflow-x: hidden;
  margin-bottom: 10vh;

  h1 {
    margin-bottom: 2vh;
  }

  p {
    margin-bottom: 5vh;
    color: var(--gray);
    font-size: 1.1vw;
    font-weight: bold;
  }


  .planning_map_area {
    width: 100vw;
    margin: 0 1vw;

    #google-map-container {
      height: 70vh;
      width: 75vw;
      position: relative;
      border-radius: var(--border-radius);
    }
  }
}

.pin-edit-area {
  position: absolute;
  top: -3vh;
  left: -10px;
  // z-index: 30;
  background-color: rgba(255, 114, 94, .95);
  padding: 3vh 2vw;
  border-radius: var(--border-radius);
  color: white;
  transform: scale(.95);

  h2 {
    margin-bottom: 3vh;
    font-size: 1.2vw;
  }
    
  label {
    width: 100%;
    
    input {
      display: block;
      margin: 1vh 0 2vh 0;
      width: 100%;
      padding: 1vh .5vw;
      border-radius: var(--border-radius);
      border: 1px solid var(--orange);
    }
  }

  button {
    border: none;
    background-color: white;
    color: var(--black);
    padding: 1vh 1vw;
    font-weight: bold;
    border-radius: var(--border-radius);
    cursor: pointer;
  }

  button:hover {
    background-color: var(--gray);
    color: white;
  }
}