.accordion {
  h2 {
    font-size: 1.4vw;
  }

  .accordion_body {
    transform: scale(.8);

    .step_card {
      width: 20%;
      margin: 0 1.5vw;
      background-color: white;
      padding: 0 2vw;
      border-radius: var(--border-radius);
      box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);

      .fa-stack {
        font-size: 1.2vw;
        position: relative;
        top: -3%;

        .fa-circle {
          color: white;
        }

        .number {
          color: var(--orange);
        }
      }

      .step_image {
        margin: 5vh;
      }

      .step_details {
        text-align: center;
        line-height: 1.3;

        h3 {
          font-size: 1.2vw;
          margin-bottom: 3vh;
        }

        p {
          font-weight: lighter;
        }
      }
    }
  }
}