.spacer {
  aspect-ratio: 960/300;
  width: 100%;
  height: 25vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
}

.spacer.layer_down {
  height: 20vh;
}

.layer,
.layer_down {
  background-image: url('../../assets/images/NavbarBg.svg');
}

.layer_down {
  transform: rotate(180deg);
  position: absolute;
  bottom: 0;
}

header {
  padding: 3vh 5vw;
  color: white;
  position: absolute;
  top: 0;
  width: 100vw;

  h1 {
    font-family: 'Dancing Script', cursive;
  }

  a {
    color: white;
    cursor: pointer;
  }

  // .left_nav > * {
  //   margin-right: 4vw;
  // }

  .links-nav > * {
    margin: 0 1vw;
  }

  .links-nav {
    button {
      background-color: transparent;
      border: 1px solid var(--white);
      color: var(--white);
      padding: 1vh 1vw;
      border-radius: var(--border-radius);
      cursor: pointer;
    }
  }
  
  .links-auth {
    width: 20%;

    .demo-btn {
      background-color: var(--white);
      color: var(--orange);
      padding: 1vh 1vw;
      border-radius: .3vw;
      font-weight: bold;
      letter-spacing: 1px;
    }

    .demo-btn:hover {
      background-color: var(--black);
      color: var(--white);
    }
  }
}

footer {
  position: relative;
  bottom: 0;
  color: white;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  width: 100vw;

  p {
    font-size: 1vw;
    opacity: .9;
  }

  a {
    color: black;
    margin-right: 2vw;
  }

  .footer_content {
    padding: 2vh 5vw;
  }
}

footer::-webkit-scrollbar {
  display: none;
}