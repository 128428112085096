*, *:before, *:after{
  box-sizing: border-box;
}

html, body, div, span, object, iframe, figure, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, code, em, img, small, strike, strong, sub, sup, tt, b, u, i, ol, ul, li, fieldset, form, label, table, caption, tbody, tfoot, thead, tr, th, td, main, canvas, embed, footer, header, nav, section, video{
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  text-size-adjust: none;
}

footer, header, nav, section, main{
  display: block;
}

body{
  line-height: 1;
}

ol, ul{
  list-style: none;
}

blockquote, q{
  quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after{
  content: '';
  content: none;
}

table{
  border-collapse: collapse;
  border-spacing: 0;
}

input{
  -webkit-appearance: none;
  border-radius: 0;
}


// Root CSS variable
:root {
  --orange: #FF725E;
  --white: #f2f2f2;
  --darkblue: #011c2d;
  --gray: #727272;
  --black: #2d2d2d;
  --title-size: 2.5vw;
  --border-radius: .5vw;
  --font-body: 'DM Sans', sans-serif;
  --box-shadow: 2px 2px 2px 2px rgba(230, 230, 230, .9);
  background-color: var(--white);
  color: var(--black);
  font-family: var(--font-body);
}


// Common elements
h1, h2, h3, h4, h5 { 
  font-weight: bold;
}

h1 {
  font-size: 3vw;
  margin-bottom: 2vh;
}

p {
  font-size: 1.2vw;
}

a {
  text-decoration: none;
  cursor: pointer;
  color: var(--orange);
}

button {
  font-family: var(--font-body);
}

button.btn {
  background-color: var(--orange);
  border: none;
  color: white;
  font-weight: bold;
  font-size: 1.1vw;
  padding: 2vh 2vw;
  border-radius: .5vw;
}


// Display Flex
.flex-row {
  display: flex;
  flex-direction: row;
} 

.flex-col {
  display: flex;
  flex-direction: column;
}

.justify-start {
  justify-content: start;
}

.justify-end {
  justify-content: end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.align-start {
  align-items: start;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: end;
}

.text-center {
  text-align: center;
}

/* TODO Add site wide styles */
form > .errors {
    color: var(--orange);
    font-size: 0.875em;
    height: 15px;
    display: flex;
    align-items: center;
}
