.outer-map-container {
  width: 100%;
}

.map-container {
  height: 300px;
}

.sidebar {
  background-color: rgba(35, 55, 75, 0.9);
  color: #fff;
  padding: 6px 12px;
  font-family: monospace;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  margin: 12px;
  border-radius: 4px;
}

.mapboxgl-canvas {
  height: 400px;
}

.info-box {
  position: absolute;
  margin: 20px;
  width: 25%;
  top: 0;
  bottom: 20px;
  padding: 20px;
  background-color: #fff;
  overflow-y: scroll;
}

.mapboxgl-canvas {
  width: 100%;
  height: auto;
}

.outer-map-container-show {
  width: 100%;
  height: 100%;
}

.planning_map {
  h1 {
    margin-bottom: 5vh;
  }
}

.planning_map_area {
  .instruction_area {
    position: absolute;
    z-index: 99;
    height: 100vh;
    background-color: rgba(242, 242, 242, .95);
  }

  .map_instructions {
    height: 70vh;
    width: 100%;
    min-width: 400px;
    min-height: 300px;
    
    #google-map-container {
      height: 100%;
    }
  }
}

.planning_map_form {
  width: 30vw;
  height: 70vh;
  margin-right: 1vw;
  overflow: scroll;
}

#google-map-container {
  width: 70vw;
}

