.game_page {
  position: relative;
  top: -25vh;

  .game-info {
    z-index: 10;
    background-color: rgba(0,0,0, 0.75);
    color: var(--white);
    margin-bottom: 2vh;
    padding: 2vh 1vw;
    width: 20vw;
    position: absolute;
    top: 1vh;
    right: .5vw;
    border-radius: var(--border-radius);

    h1, h2 {
      margin-bottom: 2vh;
    }

    h2 {
      font-size: 1.2vw;
    }

    ul > * {
      margin-bottom: 1vh;
    }

    ul {
      margin-bottom: 5vh;
    }

    .game_key {
      width: 60%;
      font-size: 1vw;
    }

    .game_value {
      font-size: 1vw;
    }
  }
  
  .google-map-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    z-index: 0;
    top: 0;
  }
}

.clue-form-box {
  position: absolute;
  width: 20vw;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.75);
  color: var(--white);
  padding: 2vh 1vw;
  top: 8vh;
  left: 0.7vw;
  border-radius: var(--border-radius);

  h2 {
    font-size: 1.3vw;
    line-height: 1.2;
  }

  h2, p, input {
    margin-bottom: 2vh;
  }

  h4 {
    margin-bottom: 1vh
  }

  p {
    line-height: 1.3;
  }

  input {
    display: block;
    margin-top: 1vh;
    width: 95%;
    padding: .5vh .5vw;
  }

  button {
    border: 0;
    padding: 1vh .75vw;
    cursor: pointer;
  }
}

.task-header {
  color: #FF725E;
}

.clue-form-box > * {
  margin-bottom: 1vh;

  .submit_response {
    margin-right: 1vw;
    margin-bottom: 1vh;
  }
}

.game_instructions {
  position: absolute;
  width: 50vw;
  top: calc(50vh - 38vh);
  left: calc(50vw - 25vw);
  background-color: white;
  padding: 5vh 3vw;
  line-height: 1.4;
  border-radius: var(--border-radius);

  h3 {
    font-size: 1.3vw;
    margin-bottom: 3vh;
 }

  ol {
    list-style: decimal;
    margin-bottom: 3vh;

    li {
      margin-bottom: 1vh;
    }
  }

  .start-button {
    cursor: pointer;
  }
}

.back {
  float: right;
}

.btn {
  cursor: pointer;
}